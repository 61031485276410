/*.q-page-container .apexcharts-tooltip {
  white-space: break-spaces;
}*/

.q-tooltip {
  z-index: 9001; // 9000 is default, but overlayd by dropdowns
}

.app-tooltip-mobile {
  word-break: normal;
  background-color: $primary;
  color: $background2;
  font-size: 1rem;
}
.q-dark .apexcharts-tooltip.apexcharts-theme-light {
  color: $dark;
}
